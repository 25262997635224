// dependencies
import React, { useState, useRef, useEffect, useLayoutEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { styled, Grid, Paper, Hidden, Box, useMediaQuery } from '@mui/material'
import elementResizeDetectorMaker from 'element-resize-detector'

import { SlideTypeEnum, SmartSwiper } from '@rtgdev/design-system'
// helpers
import { getRequiredAddon, getProductImages } from '@helpers/product'
import { getRegionZone } from '@helpers/geo-location'
import { analyticsProduct } from '@helpers/google-tag-manager'
import { useUpdateFavorites, useIsFavorited } from '@hooks/auth'
import { currencyFormatUS } from '@helpers/string-helper'

// components
import Breadcrumb from '@templates/strapi-cms/content-types/Breadcrumbs'
import TopPDPPromoBanner, {
  TopImageGalleryPDPPromoBanner,
  BottomImageGalleryPDPPromoBanner,
} from '@templates/strapi-cms/content-types/PDPPromoBanner'
import PixleeWidget from '@templates/strapi-cms/content-types/HTML/PixleeWidget'
import SaleFlag from '@shared/sale-flag'
import NoImage from '@shared/no-image'
import ProductUpgradesV2 from '@components/product/product-parts/product-upgrades-v2'
import DesktopTitle from '../../../@rtg2022/components/organisms/PDP/DesktopTitle'
import RightRail from './RightRail'
import { useProduct, FETCH_PROPERTIES, QUANTIY_PURCHASE_LIMIT } from '../hooks'
import { useCart } from '../../cart/hooks'

import DescriptionTab from './DescriptionTab'
import DescriptionMattressTab from './DescriptionMattressTab'
import ProductInfo from '../product-info'
import ProductIncludesSlider from '../product-parts/product-includes-slider'
import ProductPromotions from '../product-parts/product-promotions'
import ShopTheRoom from '../views/ShopTheRoom'
import RequiredAddons from '../views/RequiredAddons'
import { scrollToBonusBuys } from './helpers'
import '../css/shop-the-room.sass'

const SaleFlagWrapper = styled(Box)(({ theme }) => ({
  '& > span > div': {
    top: 16,
    left: 16,
  },
  [theme.breakpoints.down('sm')]: {
    '& > span > div': {
      padding: '4px 8px',

      p: {
        fontSize: '12px',
      },
    },
  },
}))

const SmartSwiperWrapper = styled(SmartSwiper)(({ theme }) => ({
  maxWidth: 914,
  margin: 0,
  '& .swiper-zoom-container': {
    height: 'calc(100vw / 3.8)',
    maxHeight: '570px',
    justifyContent: 'center',
  },
  '.swiper-zoom-container, .swiper-slide': {
    'img:not([src])': {
      opacity: 0,
    },
    'img[src]': {
      opacity: 1,
      display: 'block!important',
    },
  },
  [theme.breakpoints.down('md')]: {
    alignItems: 'center',
    display: 'flex',
    height: 'calc(100vw / 1.5)',
    '& .swiper-zoom-container': {
      justifyContent: 'center !important',
      margin: 'auto !important',
      height: 'calc(100vw / 3.2) !important',
    },
  },
  [theme.breakpoints.down('sm')]: {
    height: 'calc(100vw / 1.1)',
  },
}))

const BoxWrapper = styled(Box)(({ theme }) => ({
  padding: '0 !important',
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    marginRight: '-0.9375em',
    marginLeft: '-0.9375em',
  },

  '.swiper-container-thumbs': {
    marginLeft: '20px !important',
  },

  '.swiper-container': {
    margin: 0,
    'button.swiper-button-prev': {
      left: '20px',
    },
    [theme.breakpoints.down('md')]: {
      '.zoom-icon': {
        width: 'auto',
        height: 'auto',
        padding: '9px',
        right: 16,
        borderRadius: '50%',
        background: 'rgba(255, 255, 255, 0.5)',
        bottom: '8px',
        [theme.breakpoints.down('sm')]: {
          right: 8,
        },
        svg: {
          color: theme.palette.primary.dark,
        },
        '&:hover': {
          background: 'rgba(25, 118, 210, 0.04)',
        },
      },
    },
    '.swiper-slide .swiper-zoom-container': {
      img: {
        position: 'static !important',
        padding: '12px 0px 0px 20px',

        [theme.breakpoints.down('md')]: {
          padding: '0px',
        },
        '&.landscape': {
          [theme.breakpoints.down('md')]: {
            maxHeight: 'unset',
          },
        },
      },
      video: {
        padding: '12px 0px 0px 20px',

        [theme.breakpoints.down('md')]: {
          padding: '0px',
        },
      },
    },
  },
}))

const StyledLeftColumnContainer = styled('div')(({ theme, isLivingRoom, headerHeight = 0 }) => ({
  height: '100%',
  maxHeight: 'fit-content',
  padding: 0,
  zIndex: 1,
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    paddingBottom: '0',
    position: 'relative',
  },
  [theme.breakpoints.up('md')]: {
    height: 'fit-content',
    position: 'sticky',
    top: isLivingRoom ? headerHeight + 75 : headerHeight + 5,
    gridColumn: 1,
    width: '60vw',
    maxWidth: '914px',
  },
}))

const StyledSmartSwipperWithLabelsWrapper = styled(Grid, {
  shouldForwardProp: prop => prop !== 'iscategorybanner',
})(({ theme, iscategorybanner }) => ({
  height: iscategorybanner ? 'calc(100vw / 2.5)' : 'calc(100vw / 2.65)',
  maxHeight: 670,
  [theme.breakpoints.down('lg')]: {
    height: iscategorybanner ? 'calc(100vw / 2.5)' : 'calc(100vw / 2.65)',
  },
  [theme.breakpoints.down('md')]: {
    height: 'calc(100vw / 1.1)',
    paddingBottom: '0',
    position: 'relative',
  },
  [theme.breakpoints.up('md')]: {
    position: 'sticky',
    top: 5,
    gridColumn: 1,
    width: '60vw',
    maxWidth: '914px',
  },
  '@media (max-width: 768px)': {
    '& > div:nth-of-type(3)': {
      height: '100% !important',
    },
  },
}))

const StyledRightColumnContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    gridColumn: 2,
  },
  '& > div': {
    padding: 15,
  },
}))

const StyledAddonsContainer = styled(Grid)(({ theme }) => ({
  padding: '0 15px',
  backgroundColor: theme.palette.common.white,
}))

const PaperWrapper = styled(Paper)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'grid',
    gap: '32px',
  },
  '& > div': {
    padding: 0,
  },
}))

const StyledDesktopTitle = styled(DesktopTitle)(({ theme, headerHeight }) => ({
  padding: '0.6rem 0.6rem',
  backgroundColor: theme.palette.common.white,
  position: 'sticky',
  top: headerHeight,
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
  '#tt-teaser-widget': {
    position: 'absolute',
    top: '70%',
    paddingLeft: '6.4px',
  },
  '.container-price': {
    flexDirection: 'row-reverse',
    justifyContent: 'end',
    '.container-price-info': {
      paddingLeft: '12px',
    },
    '& > div:first-of-type': {
      '.MuiBox-root': {
        paddingLeft: '5px',
      },
    },
    '& > div:last-of-type': {
      padding: '0!important',
      '.MuiBox-root': {
        alignItems: 'flex-end',
        '& > *': {
          marginRight: 0,
        },
      },
    },
  },
}))

const StyledPDPPromoBanner = styled(TopPDPPromoBanner)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
}))

const StyledStickyWrapper = styled('div')(({ theme }) => ({
  position: 'block',
  [theme.breakpoints.up('md')]: {
    position: 'sticky',
    maxHeight: 'fit-content',
    maxWidth: '914px',
    height: 'fit-content',
  },
}))

const getUpgradesWithAnalytics = ({ room_package_upgrades, sku }) => {
  const filteredUpgrades = room_package_upgrades?.filter(u => u.sku && u.title && u.pricing)
  const upgradesWithAnalytics = filteredUpgrades?.map((upgrade, index) => ({
    ...upgrade,
    analyticsProduct: analyticsProduct(upgrade, 1, index + 1),
  }))
  const selectedUpgrade = upgradesWithAnalytics.find(item => item?.sku === sku) || null
  const nonSelectedUpgrades = upgradesWithAnalytics.filter(item => item?.sku !== sku)
  return { upgradesWithAnalytics, selectedUpgrade, nonSelectedUpgrades }
}

const getProductMediaUrls = (product, isMobile = false) => {
  const thumbnailVideo = `${product.video_thumbnail ||
    product?.primary_image_room ||
    (product?.primary_image ? `${product?.primary_image}&h=52px` : `${product?.alternate_images?.[0]}&h=52`)}`

  const productVideos =
    product?.video?.map(url => ({
      url,
      alt: product?.displayNamePDP || product.title,
      originalSrc: url,
      type: SlideTypeEnum.htmlvideo,
      thumbnail: thumbnailVideo,
    })) || []

  const productAssets =
    getProductImages(product, isMobile)?.reduce((assets, url) => {
      // Since video assets show up with the 'video' property, we no longer want to include any youtube links that show
      // up in alternate_images
      if (url?.includes?.('youtube')) {
        return assets
      }
      const slide = {
        url: `${url}&h=1190&w=1190`,
        alt: product?.displayNamePDP || product.title,
        // originalSrc would contain the original src/url of the image without controlling height and width
        originalSrc: url,
        imageError: false,
      }
      assets.push({
        ...slide,
        type: SlideTypeEnum.image,
        thumbnail: `${url}&h=52`,
      })
      return assets
    }, []) || []

  return [...productAssets.slice(0, 2), ...productVideos, ...productAssets.slice(2)]
}

const handleSwiperImagesAspectRatio = () => {
  const swiperImages = document !== undefined && document.querySelectorAll('.swiper-zoom-container>img')
  const fixImageIfPortrait = image => {
    if (image.naturalWidth / image.naturalHeight > 1.3) {
      image?.classList?.add('landscape')
    } else {
      image?.classList?.remove('landscape')
    }
  }
  Array.from(swiperImages).forEach(image => {
    if (image?.complete) {
      fixImageIfPortrait(image)
    }
    image.addEventListener('load', () => fixImageIfPortrait(image))
  })
}

/**
 * ProductDetail controller
 * -  Renders the PDP presentational component (refactored)
 * -  Documents business and implementation logic for all PDP components?
 * @param {*} props
 */
const ProductDetailView = ({
  product,
  items_in_room,
  promotions,
  availabilityDate,
  room_package_upgrades = [],
  see_in_room,
  foundationsMattressBanner,
  isPLA = false,
}) => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'))
  const { region } = getRegionZone()
  const addons = product?.addon_items || []
  const availableAddons = addons?.filter(addon => !!addon.catalog_availability[region])
  const requiredAddons = availableAddons?.filter(addon => addon.addon_required)
  const formattedAddon = getRequiredAddon(requiredAddons)
  const [quantity, setQuantity] = useState(1)
  const [warrantyEnabled, setWarrantyEnabled] = useState(false)
  const [showWarrantyModal, setShowWarrantyModal] = useState(false)
  const [userSelectedAddons, setUserSelectedAddons] = useState([])
  const [requiredAddonsSelected, setRequiredAddonsSelected] = useState([])
  const [headerHeight, setHeaderHeight] = useState(0)
  const [isCategoriesPromoBannerAvailable, setCategoriesPromoBannerAvailable] = useState(false)
  const productMediaUrls = getProductMediaUrls(product, isMobile)
  const { selectedUpgrade, nonSelectedUpgrades } = getUpgradesWithAnalytics({
    room_package_upgrades,
    sku: product.sku,
  })
  const promoRef = useRef(null)

  // For rare case when itemizedDimensions exists but contains no actual dimension data, see 99475812.
  // If it has less than 4 properties it has no dimensions, since all iDs have at least name, img, and sku

  // const hasItemizedDimensions = product.itemizedDimensions
  //   ? Array.isArray(product.itemizedDimensions) || Object.keys(product.itemizedDimensions).length > 3
  //   : false

  useEffect(() => {
    const resizeListener = elementResizeDetectorMaker({
      strategy: 'scroll', // <- For ultra performance.
    })
    if (typeof document !== 'undefined' && document.getElementsByClassName('desktop-only')?.[0]) {
      resizeListener.listenTo(document.getElementsByClassName('desktop-only')?.[0], element => {
        setHeaderHeight(element?.offsetHeight)
      })
    }
  })

  //  *************  Favorites Logic *****************************************************************
  const isFavorited = useIsFavorited(product.sku)
  const handleFavoriteClick = useUpdateFavorites(product)

  const getDimensionImage = () => {
    if (!foundationSelection?.dimension_image) return [product?.dimension_image]
    if (product?.dimension_image) return [product?.dimension_image, foundationSelection?.dimension_image]
    return []
  }

  //  *************  Mattress PDP Logic *****************************************************************
  const [foundationSelection, setFoundationSelection] = useState({})
  const {
    sku,
    isMattressWithFoundations,
    collection,
    enhancedRelationships,
    isLivingRoom,
    price,
    stockMessage,
    availability,
    sale,
    isStrikeThrough,
    priceStrikeThrough,
    warrantyDescription,
    routeToSimilarItems,
    breadcrumbs,
    category,
    subCategory,
  } = useProduct(
    {
      product,
    },
    [FETCH_PROPERTIES.AVAILABILITY_STOCKMESSAGE],
  )

  const { renderAddToCartModal, onAddToCart } = useCart({
    quantity,
    availability,
    product,
    price,
    componentPage: 'pdp',
    stockMessage,
    warrantyEnabled,
    addons,
    activeAddons: userSelectedAddons,
    requiredAddons: requiredAddonsSelected,
    showLocation: false,
    moreInfoButton: false,
    region,
    source: 'pdp',
    index: 0,
  })

  const [isActiveSlideAVideo, setIsActiveSlideAVideo] = useState(false)

  const onAddToCartActions = useMemo(() => {
    if (!availability)
      return {
        action: 'SHOP SIMILAR PRODUCTS',
        onClick: routeToSimilarItems,
      }
    return {
      action: 'ADD TO CART',
      onClick: onAddToCart,
    }
  }, [availability, onAddToCart, routeToSimilarItems])

  useLayoutEffect(() => {
    handleSwiperImagesAspectRatio()
  })

  const handleSetQuantity = qnt => {
    if (qnt > 0 && qnt <= QUANTIY_PURCHASE_LIMIT) setQuantity(qnt)
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={6} md={7} lg={8}>
          <Breadcrumb productBreadcrumbs={breadcrumbs} productTitle={product?.displayNamePDP || product.title} isPDP />
        </Grid>
        <Grid container item xs={12} sm={6} md={5} lg={4} justifyContent="flex-end">
          <ShopTheRoom product={product} single_item_room={product.single_item_room} see_in_room={see_in_room} />
        </Grid>
      </Grid>
      <StyledPDPPromoBanner
        sku={product?.sku}
        region={region}
        productCategory={category}
        productSubcategory={subCategory}
      />

      {!isMattressWithFoundations && (
        <>
          {renderAddToCartModal()}
          <StyledDesktopTitle
            availability={availability}
            headerHeight={headerHeight}
            title={product?.displayNamePDP || product?.title}
            price={currencyFormatUS(price)}
            isFavorited={isFavorited}
            addToCartLabel={onAddToCartActions.action}
            addToCartClick={onAddToCartActions.onClick}
            sale={sale}
            strikethrough={isStrikeThrough}
            strikeThroughPrice={priceStrikeThrough}
            handleFavoriteClick={handleFavoriteClick}
            roomSavings={selectedUpgrade?.room_savings}
          />
        </>
      )}
      <BoxWrapper>
        <PaperWrapper square elevation={0} wrap="nowrap">
          <StyledLeftColumnContainer isLivingRoom={isLivingRoom} headerHeight={headerHeight}>
            <StyledStickyWrapper>
              {isPLA && (
                <TopImageGalleryPDPPromoBanner
                  sku={product?.sku}
                  region={region}
                  productCategory={category}
                  productSubcategory={subCategory}
                />
              )}
              <StyledSmartSwipperWithLabelsWrapper>
                {!isActiveSlideAVideo && (
                  <SaleFlagWrapper>
                    <SaleFlag
                      product={product}
                      bonusBuyOverrideText="CLICK FOR BONUS BUY!"
                      availabilityDate={availabilityDate}
                      onClick={promoRef?.current ? () => scrollToBonusBuys(promoRef, isMobile) : null}
                    />
                  </SaleFlagWrapper>
                )}
                {productMediaUrls.length > 0 ? (
                  <SmartSwiperWrapper
                    onSetModalOpen={() => handleSwiperImagesAspectRatio()}
                    onSlideChange={(s, slides) =>
                      setIsActiveSlideAVideo(
                        slides[s.activeIndex].type === 'htmlvideo' || slides[s.activeIndex].type === 'youtube',
                      )
                    }
                  >
                    {productMediaUrls.map((el, index) => {
                      const loading = index === 0 ? 'eager' : 'lazy'
                      const fetchPriority = index === 0 ? 'high' : 'low'
                      return (
                        <SmartSwiper.Slide
                          key={el.url}
                          type={el.type}
                          url={el.url}
                          alt={el.imageError ? `${el.alt} - Failed to load image` : el.alt}
                          thumbnail={el.thumbnail}
                          originalSrc={el.originalSrc}
                          fetchPriority={fetchPriority}
                          loading={loading}
                        />
                      )
                    })}
                  </SmartSwiperWrapper>
                ) : (
                  <NoImage />
                )}
              </StyledSmartSwipperWithLabelsWrapper>
              <BottomImageGalleryPDPPromoBanner
                sku={product?.sku}
                region={region}
                productCategory={category}
                productSubcategory={subCategory}
                productCollection={product?.collection}
              />
            </StyledStickyWrapper>
          </StyledLeftColumnContainer>
          <StyledRightColumnContainer>
            {/* If living room render new PDP else render legacy */}
            {!isMattressWithFoundations ? (
              <RightRail
                availability={availability}
                stockMessage={stockMessage}
                product={product}
                quantity={quantity}
                quantityLimit={QUANTIY_PURCHASE_LIMIT}
                setQuantity={handleSetQuantity}
                warrantyEnabled={warrantyEnabled}
                setWarrantyEnabled={setWarrantyEnabled}
                requiredAddonsSelected={requiredAddonsSelected}
                setUserSelectedAddons={setUserSelectedAddons}
                userSelectedAddons={userSelectedAddons}
                showWarrantyModal={showWarrantyModal}
                setShowWarrantyModal={setShowWarrantyModal}
                roomSavings={selectedUpgrade?.room_savings}
                foundationSelection={foundationSelection}
                items_in_room={items_in_room}
                isFavorited={isFavorited}
                handleFavoriteClick={handleFavoriteClick}
              />
            ) : (
              <ProductInfo
                fullWidth
                viewType="list"
                data={product}
                items_in_room={items_in_room}
                promotions={promotions}
                requiredAddonsSelected={requiredAddonsSelected}
                setUserSelectedAddons={setUserSelectedAddons}
                userSelectedAddons={userSelectedAddons}
                room_savings={selectedUpgrade?.room_savings}
                foundationsMattressBanner={foundationsMattressBanner}
                foundationSelection={foundationSelection}
                setFoundationSelection={setFoundationSelection}
              />
            )}
          </StyledRightColumnContainer>
        </PaperWrapper>
        {formattedAddon?.title && (
          <StyledAddonsContainer item container xs={12}>
            <RequiredAddons
              requiredAddons={requiredAddons}
              setRequiredAddonsSelected={setRequiredAddonsSelected}
              setUserSelectedAddons={setUserSelectedAddons}
              userSelectedAddons={userSelectedAddons}
            />
          </StyledAddonsContainer>
        )}
      </BoxWrapper>
      {/* => OTHER ROOM OPTIONS */}
      {selectedUpgrade && nonSelectedUpgrades && nonSelectedUpgrades?.length > 0 && (
        <ProductUpgradesV2 upgrades={nonSelectedUpgrades} selectedUpgrade={{ ...selectedUpgrade, pdp_price: price }} />
      )}
      {/* => ITEMS IN THIS ROOM */}
      {items_in_room?.length > 0 && !product.single_item_room && (
        <div className="grid-container">
          <ProductIncludesSlider items_in_room={items_in_room} product={product} heading="Items in this room" />
        </div>
      )}
      {/* => PRODUCT PROMOTIONS */}
      {promotions && (
        <Grid item>
          <div ref={promoRef} className="grid-x grid-margin-y grid-margin-x">
            <ProductPromotions
              product={product}
              promotions={promotions}
              promoType={promotions.offer_template}
              addons={addons}
              activeAddons={userSelectedAddons}
            />
          </div>
        </Grid>
      )}
      {/* PIXLEE UGC */}
      <PixleeWidget html={`<div id="pixlee_container" />`} sku={product?.sku} />
      {/* => PRODUCT DESCRIPTION */}
      <Grid item sx={{ margin: { xs: '0 -0.9375em', md: 0 } }}>
        {isMattressWithFoundations ? (
          <DescriptionMattressTab
            dimensionImage={getDimensionImage()}
            description={product.description}
            dimensions={product.dimensions}
            comfort={product.comfort}
            pressure_relief={product.pressure_relief}
            sleep_position={product.sleep_position}
            support_level={product.support_level}
            temperature_management={product.temperature_management}
            size={product.size}
            technology={product.technology}
            warrantyDescription={warrantyDescription}
            warrantyLink={product.warrantyLink}
            product={product}
          />
        ) : (
          <DescriptionTab
            assemblyInstructions={product.assembly_instructions}
            dimensionImage={getDimensionImage()}
            description={product.description}
            dimensions={product.dimensions}
            warrantyLink={product.warrantyLink}
            warrantyDescription={warrantyDescription}
            product={product}
          />
        )}
      </Grid>
    </>
  )
}

ProductDetailView.propTypes = {
  items_in_room: PropTypes.array,
  see_in_room: PropTypes.objectOf(PropTypes.array),
  product: PropTypes.shape({
    addon_items: PropTypes.array,
    alternate_images: PropTypes.array,
    brand: PropTypes.string,
    breadcrumb: PropTypes.shape({
      breadcrumb_label: PropTypes.string,
      breadcrumb_url: PropTypes.string,
    }),
    description: PropTypes.string,
    dimensions: PropTypes.string,
    dimension_image: PropTypes.string,
    itemizedDimensions: PropTypes.shape({
      width: PropTypes.string,
      height: PropTypes.string,
      depth: PropTypes.string,
    }),
    comfort: PropTypes.string,
    pressure_relief: PropTypes.string,
    sleep_position: PropTypes.string,
    support_level: PropTypes.string,
    temperature_management: PropTypes.string,
    selections: PropTypes.shape({
      foundation: PropTypes.array,
    }),
    breadCrumbs: PropTypes.any,
    isAvailable: PropTypes.bool,
    on_promotion: PropTypes.object,
    primary_image_item: PropTypes.string,
    primary_image_room: PropTypes.string,
    primary_image: PropTypes.string,
    promotions: PropTypes.objectOf(PropTypes.any),
    room_configurations: PropTypes.object,
    saleFlag: PropTypes.bool,
    see_in_room: PropTypes.objectOf(PropTypes.array),
    single_item_room: PropTypes.bool,
    size: PropTypes.string,
    sku: PropTypes.string,
    technology: PropTypes.string,
    assembly_instructions: PropTypes.array,
    warrantyLink: PropTypes.string,
    warranty_description: PropTypes.string,
    title: PropTypes.string,
    displayNamePDP: PropTypes.string,
    collection: PropTypes.string,
  }),
  room_package_upgrades: PropTypes.arrayOf(PropTypes.any),
  availabilityDate: PropTypes.any,
  promotions: PropTypes.any,
  foundationsMattressBanner: PropTypes.objectOf(PropTypes.any),
  isPLA: PropTypes.bool,
}

export default ProductDetailView
